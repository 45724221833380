import React, { useEffect, useRef } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './aboutus.css';

const AboutUs = () => {
    const diagramContainerRef = useRef(null);
    const timeoutRef = useRef<number | null>(null); // Use a ref to store the timeout ID

    useEffect(() => {
        const diagramNodes = document.querySelectorAll<HTMLElement>('.diagram-node span');

        let currentIndex = 0;

        const pulsateNodes = () => {
            // Remove the pulsating class from all nodes
            diagramNodes.forEach(node => {
                node.classList.remove('pulsating');
            });

            // Add the pulsating class to the current node
            diagramNodes[currentIndex].classList.add('pulsating');

            // Update the index to move to the next node
            currentIndex = (currentIndex + 1) % diagramNodes.length;

            // Set the timeout for the next pulsation
            timeoutRef.current = window.setTimeout(pulsateNodes, 1000); // 1 second delay for each pulse
        };

        pulsateNodes(); // Start the pulsating effect

        return () => {
            // Cleanup the timeout if the component unmounts
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return (
        <>
            <Navbar />
            <div className="aboutus-container">
                <div className="page-header">
                    <h1>ABOUT US</h1>
                    <p>We are redefining the global trade landscape through innovation and efficiency.</p>
                </div>

                <div className="main-content">
                    <img src="./globalTrade.jpg" alt="Global Trade" />
                    <div className="text-column">
                        <p>
                            Our vision is to become the world's leading digital supply chain platform for goods and services in global trade.
                            By offering unmatched supplier sourcing solutions, seamless buyer-supplier matchmaking, and
                            secure cross-border trade facilitation, we aim to revolutionize global trade. Our mission is to enhance
                            efficiency, transparency, and security across the entire supply chain,
                            from supplier connections to trade fulfillment.
                        </p>
                    </div>
                </div>

                <div className="diagram-container" ref={diagramContainerRef}>
                    <div className="diagram-line"></div> {/* Line element behind nodes */}
                    <div className="diagram-node">
                        <span>Market</span>
                    </div>
                    <div className="diagram-node">
                        <span>Supplier</span>
                    </div>
                    <div className="diagram-node">
                        <span>Negotiation</span>
                    </div>
                    <div className="diagram-node">
                        <span>Trade Fulfillment</span>
                    </div>
                    <div className="diagram-node">
                        <span>Security</span>
                    </div>
                </div>

                {/* Download PDF Section */}
                <div className="download-section">
                    <p>Click here to download a PDF with more information on how our <strong>international trade brokerage</strong> services can
                        help you connect with the right <strong>suppliers</strong> and <strong>buyers</strong>:</p>
                    <a href="/company-info.pdf" download>
                        <button className="download-button">Download PDF</button>
                    </a>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;
