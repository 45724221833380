import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './services.css';

const Services = () => {
  const cardRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];

  useEffect(() => {
    let currentIndex = 0;

    const animateCards = () => {
      if (cardRefs[currentIndex].current) {
        // Add the enlarging class to the current card
        cardRefs[currentIndex].current!.classList.add('enlarging');

        // Remove the class after the animation duration (3s)
        setTimeout(() => {
          cardRefs[currentIndex].current!.classList.remove('enlarging');

          // Move to the next card, looping back to the first card
          currentIndex = (currentIndex + 1) % cardRefs.length;

          // Call the function again to animate the next card
          animateCards();
        }, 3000); // 3s duration for each animation cycle
      }
    };

    // Start the animation sequence
    animateCards();
  }, [cardRefs]);

  return (
    <>
      <Navbar />
      <div className="services-container">
        <div className="page-header">
          <h1>HOW WE HELP YOU</h1>
        </div>
        <div className="services-cards">
          {/* Finding Buyers Card */}
          <div className="service-card" ref={cardRefs[0]}>
            <img src="./handshake-svgrepo-com.svg" alt="Finding Buyers" className="service-icon" />
            <h2>Finding You Buyers</h2>
            <p>
              Our platform connects you with a vast network of global buyers, ensuring your products reach the right market.
              Whether youre targeting specific industries or new international markets, we help facilitate buyer-supplier matchmaking.
            </p>
          </div>

          {/* Finding Suppliers Card */}
          <div className="service-card" ref={cardRefs[1]}>
            <img src="/manufacturer-svgrepo-com.svg" alt="Finding Suppliers" className="service-icon" />
            <h2>Finding You Suppliers</h2>
            <p>
              We help you source reliable suppliers, giving you access to high-quality materials and products at competitive prices.
            </p>
          </div>

          {/* Security Card */}
          <div className="service-card" ref={cardRefs[2]}>
            <img src="/shield-check-svgrepo-com.svg" alt="Security" className="service-icon" />
            <h2>Security</h2>
            <p>
              Our platform prioritizes security, ensuring that all transactions and communications are encrypted and secure.
            </p>
          </div>
        </div>

        {/* Contact Button */}
        <div className="contact-button-container">
          <Link to="/contact">
            <button className="contact-button">Get in Contact</button>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
