export const globeConfig = {
  pointSize: 1,
  globeColor: "#062056",
  showAtmosphere: true,
  atmosphereColor: "#ffffff",
  atmosphereAltitude: 0.1,
  emissive: "#000000",
  emissiveIntensity: 0.1,
  shininess: 0.9,
  polygonColor: "rgba(255,255,255,0.7)",
  ambientLight: "#000000",
  directionalLeftLight: "#000000",
  directionalTopLight: "#ffffff",
  pointLight: "#ffffff",
  flightTime: 1000,
  flightLength: 0.9,
  rings: 1,
  maxRings: 3,
  initialPosition: { lat: 22.3193, lng: 114.1694 },
  autoRotate: true,
  autoRotateSpeed: 0.5,
};

export const flights = [
  {
    order: 1,
    from: "London, UK",
    to: "New York, USA",
    startLat: 51.5072,
    startLng: -0.1276,
    endLat: 40.7128,
    endLng: -74.0060,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 2,
    from: "Moscow, Russia",
    to: "Kyiv, Ukraine",
    startLat: 55.7558,
    startLng: 37.6173,
    endLat: 50.4501,
    endLng: 30.5234,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 3,
    from: "Sau Paulo, Brazil",
    to: "Johannesburg, South Africa",
    startLat: -23.5505,
    startLng: -46.6333,
    endLat: -26.2041,
    endLng: 28.0473,
    arcAlt: 0.4,
    color: "#FFFFFF",
  },
  {
    order: 4,
    from: "Shanghai, China",
    to: "Hong Kong, Hong Kong, China",
    startLat: 31.2304,
    startLng: 121.4737,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 5,
    from: "Dubai, UAE",
    to: "Doha, Qatar",
    startLat: 25.2048,
    startLng: 55.2708,
    endLat: 25.276987,
    endLng: 51.520008,
    arcAlt: 0.1,
    color: "#FFFFFF",
  },
  {
    order: 6,
    from: "Lagos, Nigeria",
    to: "Johannesburg, South Africa",
    startLat: 6.5244,
    startLng: 3.3792,
    endLat: -26.2041,
    endLng: 28.0473,
    arcAlt: 0.4,
    color: "#FFFFFF",
  },
  {
    order: 7,
    from: "Munich, Germany",
    to: "Amsterdam, Netherlands",
    startLat: 48.1351,
    startLng: 11.5820,
    endLat: 52.3676,
    endLng: 4.9041,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
  {
    order: 8,
    from: "Istanbul, Turkey",
    to: "Mumbai, India",
    startLat: 41.0082,
    startLng: 28.9784,
    endLat: 19.0760,
    endLng: 72.8777,
    arcAlt: 0.3,
    color: "#FFFFFF",
  },
  {
    order: 9,
    from: "Lahore, Pakistan",
    to: "Dubai, UAE",
    startLat: 31.5497,
    startLng: 74.3436,
    endLat: 25.2048,
    endLng: 55.2708,
    arcAlt: 0.2,
    color: "#FFFFFF",
  },
];
