import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import './privacy.css';

const Privacy = () => {
    return (
        <>
        <Navbar/>
            <div className="privacy-block">
                <header>
                    <h1>PRIVACY POLICY</h1>
                    <h2>Last updated September 26, 2024</h2>
                </header>

                <section>
                    <p>This Privacy Notice for GLOTON TRADE MANAGEMENT NETWORK LTD ('we', 'us', or 'our'), describes how and why we might access, collect, store, use, and/or share ('process') your personal information when you use our services ('Services'), including when you:</p>
                    <ul>
                        <li>Visit our website at <a href="https://www.gloton.co.uk">https://www.gloton.co.uk</a>, or any website of ours that links to this Privacy Notice</li>
                        <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                    </ul>
                    <p>Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:admin@gloton.co.uk">admin@gloton.co.uk</a>.</p>
                </section>

                <section>
                    <h2>SUMMARY OF KEY POINTS</h2>
                    <p className="highlight">This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.</p>

                    <ul>
                        <li><strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use.</li>
                        <li><strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.</li>
                        <li><strong>Do we collect any information from third parties?</strong> We do not collect any information from third parties.</li>
                        <li><strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, and for security and fraud prevention.</li>
                        <li><strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties.</li>
                        <li><strong>How do we keep your information safe?</strong> We have adequate organisational and technical processes and procedures in place to protect your personal information.</li>
                        <li><strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</li>
                        <li><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by contacting us.</li>
                    </ul>
                </section>

                <section>
                    <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
                    <p><strong>Personal information you disclose to us</strong></p>
                    <p><em>In Short:</em> We collect personal information that you provide to us.</p>
                    <p>We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

                    <p><strong>Personal Information Provided by You:</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                    <ul>
                        <li>names</li>
                        <li>phone numbers</li>
                        <li>email addresses</li>
                        <li>contact or authentication data</li>
                    </ul>

                    <p><strong>Sensitive Information:</strong> We do not process sensitive information.</p>
                </section>

                <section>
                    <h2>Information automatically collected</h2>
                    <p><em>In Short:</em> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>
                    <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as:</p>
                    <ul>
                        <li>IP address</li>
                        <li>browser and device characteristics</li>
                        <li>operating system</li>
                        <li>language preferences</li>
                        <li>location data (based on your IP address or device settings)</li>
                    </ul>
                </section>

                <section>
                    <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                    <p><strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent.</p>
                    <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                    <ul>
                        <li>To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
                    </ul>
                </section>

                <section>
                    <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
                    <p><strong>In Short:</strong> We only process your personal information when we believe it is necessary, and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.</p>

                    <p><strong>If you are located in the EU or UK, this section applies to you:</strong></p>
                    <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:</p>
                    <ul>
                        <li><strong>Consent.</strong> We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw your consent at any time.</li>
                        <li><strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations.</li>
                        <li><strong>Vital Interests.</strong> We may process your information where necessary to protect your vital interests or the vital interests of a third party.</li>
                    </ul>

                    <p><strong>If you are located in Canada, this section applies to you:</strong></p>
                    <p>We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can withdraw your consent at any time.</p>
                    <p>In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:</p>
                    <ul>
                        <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                        <li>For investigations and fraud detection and prevention</li>
                        <li>For business transactions provided certain conditions are met</li>
                        <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                        <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                        <li>If we have reasonable grounds to believe an individual has been, is, or may be a victim of financial abuse</li>
                        <li>If disclosure is required to comply with a subpoena, warrant, or court order</li>
                        <li>If the information is publicly available and is specified by regulations</li>
                    </ul>
                </section>

                <section>
                    <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                    <p><strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.</p>
                    <p>We may need to share your personal information in the following situations:</p>
                    <ul>
                        <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                    </ul>
                </section>

                <section>
                    <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                    <p><strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</p>
                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</p>
                    <p>We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising. These technologies help manage and display advertisements, tailor them to your interests, or send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.</p>
                </section>


                <section>
                    <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                    <p>
                        To the extent these online tracking technologies are deemed to be a 'sale'/'sharing' (which includes targeted advertising, as defined under applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section <strong>'DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?'</strong> Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
                    </p>
                    <p>
                        <strong>Google Analytics:</strong> We may share your information with Google Analytics to track and analyse the use of the Services. The Google Analytics Advertising Features that we may use include: Google Analytics Demographics and Interests Reporting. To opt out of being tracked by Google Analytics across the Services, visit <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-Out</a>. You can opt out of Google Analytics Advertising Features through <a href="https://adssettings.google.com/" >Ads Settings</a> and <a href="https://support.google.com/googleplay/android-developer/answer/9854773?hl=en" >Ad Settings for mobile apps</a>. Other opt out means include <a href="http://optout.networkadvertising.org/" >Network Advertising Opt-Out</a> and <a href="http://www.networkadvertising.org/mobile-choice" >Network Advertising Mobile Choice</a>. For more information on the privacy practices of Google, please visit the <a href="https://policies.google.com/privacy">Google Privacy & Terms</a> page.
                    </p>
                    <p>
                        <strong>HubSpot Analytics </strong>We may share your information with HubSpot Analytics to track and analyze the use of our Services.
                        HubSpot Analytics helps us understand how users engage with our platform, allowing us to improve
                        our offerings and enhance your experience.
                    </p>
                </section>

                <section>
                    <h2>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                    <p>
                        <strong>In Short:</strong> We keep your information for as long as necessary to fulfil the purposes outlined in this Privacy Notice unless otherwise required by law. We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
                    </p>
                    <p>
                        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                    </p>
                </section>

                <section>
                    <h2>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                    <p>
                        <strong>In Short:</strong> We aim to protect your personal information through a system of organisational and technical security measures. We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process.
                    </p>
                    <p>
                        However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure. Therefore, we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
                    </p>
                </section>

                <section>
                    <h2>9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                    <p>
                        <strong>In Short:</strong> Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
                    </p>
                    <p>
                        In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making.
                    </p>
                    <p>
                        In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us using the contact details provided in the section <strong>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</strong> below. We will consider and act upon any request in accordance with applicable data protection laws.
                    </p>
                    <p>
                        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority. If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
                    </p>
                    <p>
                        <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent by contacting us using the contact details provided in the section <strong>'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</strong> below.
                    </p>
                    <p>
                        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent. If you have questions or comments about your privacy rights, you may email us at <a href="mailto:admin@gloton.co.uk">admin@gloton.co.uk</a>.
                    </p>
                </section>

                <section>
                    <h2>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                    <p>
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.
                    </p>
                    <p>
                        California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognising or honouring DNT signals, we do not respond to them at this time.
                    </p>
                </section>

                <section>
                    <h2>11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
                    <p>
                        <strong>In Short:</strong> If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information.
                    </p>
                    <p>
                        You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.
                    </p>
                    <h2>Categories of Personal Information We Collect</h2>
                    <p>
                        We have collected the following categories of personal information in the past twelve (12) months:
                    </p>
                    <ul>
                        <li>Personal identification information (e.g., name, email address)</li>
                        <li>Device information (e.g., IP address, browser type)</li>
                        <li>Usage data (e.g., time spent on the website, pages visited)</li>
                        <li>Marketing data (e.g., preferences, responses to surveys)</li>
                    </ul>
                </section>
                <div>
                    <h2 style={{ marginBottom: '20px' }}>Category Examples Collected</h2>
                    <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#f2f2f2' }}>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Category</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Examples</th>
                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Collected</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>A. Identifiers</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>YES</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>B. Personal information as defined in the California Customer Records statute</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Name, contact information, education, employment, employment history, and financial information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>YES</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>C. Protected classification characteristics under state or federal law</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>NO</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>D. Commercial information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Transaction information, purchase history, financial details, and payment information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>NO</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>E. Biometric information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Fingerprints and voiceprints</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>NO</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>F. Internet or other similar network activity</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>NO</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>G. Geolocation data</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Device location</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>NO</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>H. Audio, electronic, sensory, or similar information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Images and audio, video or call recordings created in connection with our business activities</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>NO</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>I. Professional or employment-related information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>YES</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>J. Education Information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Student records and directory information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>NO</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>K. Inferences drawn from collected personal information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>NO</td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>L. Sensitive personal information</td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}></td>
                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>NO</td>
                            </tr>
                        </tbody>
                    </table>

                    <p style={{ marginBottom: '10px' }}>
                        We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
                    </p>
                    <ul style={{ marginBottom: '20px' }}>
                        <li>Receiving help through our customer support channels;</li>
                        <li>Participation in customer surveys or contests;</li>
                        <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                    </ul>
                    <p style={{ marginBottom: '10px' }}>
                        We will use and retain the collected personal information as needed to provide the Services or for:
                    </p>
                    <ul style={{ marginBottom: '20px' }}>
                        <li>Category A - As long as the user has an account with us</li>
                        <li>Category B - As long as the user has an account with us</li>
                        <li>Category H - As long as the user has an account with us</li>
                        <li>Category I - As long as the user has an account with us</li>
                    </ul>

                    <p style={{ marginBottom: '10px' }}>
                        Sources of Personal Information: Learn more about the sources of personal information we collect in 'WHAT INFORMATION DO WE COLLECT?'
                    </p>
                    <p>How We Use and Share Personal Information</p>
                </div>
                <p style={{ marginBottom: '20px' }}>
                    Learn about how we use your personal information in the section,
                    <strong> 'HOW DO WE PROCESS YOUR INFORMATION?'</strong> Will your information be shared with anyone else?
                    We may disclose your personal information with our service providers
                    pursuant to a written contract between us and each service provider.
                    Learn more about how we disclose personal information in the section,
                    <strong> 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'</strong>
                </p>

                <p style={{ marginBottom: '20px' }}>
                    We may use your personal information for our own business purposes,
                    such as for undertaking internal research for technological development
                    and demonstration. This is not considered to be 'selling' of your personal information.
                </p>

                <p style={{ marginBottom: '20px' }}>
                    We have not disclosed, sold, or shared any personal information to third parties
                    for a business or commercial purpose in the preceding twelve (12) months.
                    We will not sell or share personal information in the future belonging
                    to website visitors, users, and other consumers.
                </p>

                <h2 style={{ marginBottom: '10px' }}>Your Rights</h2>
                <p style={{ marginBottom: '20px' }}>
                    You have rights under certain US state data protection laws.
                    However, these rights are not absolute, and in certain cases, we may decline
                    your request as permitted by law. These rights include:
                </p>
                <ul style={{ marginBottom: '20px', paddingLeft: '20px' }}>
                    <li>Right to know whether or not we are processing your personal data</li>
                    <li>Right to access your personal data</li>
                    <li>Right to correct inaccuracies in your personal data</li>
                    <li>Right to request the deletion of your personal data</li>
                    <li>Right to obtain a copy of the personal data you previously shared with us</li>
                    <li>Right to non-discrimination for exercising your rights</li>
                    <li>
                        Right to opt out of the processing of your personal data if it is used
                        for targeted advertising (or sharing as defined under California’s privacy law),
                        the sale of personal data, or profiling in furtherance of decisions that
                        produce legal or similarly significant effects ('profiling')
                    </li>
                </ul>
                <p style={{ marginBottom: '20px' }}>
                    Depending upon the state where you live, you may also have the following rights:
                </p>
                <ul style={{ marginBottom: '20px', paddingLeft: '20px' }}>
                    <li>
                        Right to obtain a list of the categories of third parties to which we have
                        disclosed personal data (as permitted by applicable law, including
                        California's and Delaware's privacy law)
                    </li>
                    <li>
                        Right to obtain a list of specific third parties to which we have disclosed
                        personal data (as permitted by applicable law, including Oregon’s privacy law)
                    </li>
                    <li>
                        Right to limit use and disclosure of sensitive personal data (as permitted
                        by applicable law, including California’s privacy law)
                    </li>
                    <li>
                        Right to opt out of the collection of sensitive data and personal data
                        collected through the operation of a voice or facial recognition feature
                        (as permitted by applicable law, including Florida’s privacy law)
                    </li>
                </ul>

                <h2 style={{ marginBottom: '10px' }}>How to Exercise Your Rights</h2>
                <p style={{ marginBottom: '20px' }}>
                    To exercise these rights, you can contact us by emailing us at <strong>admin@gloton.co.uk</strong>,
                    or by referring to the contact details at the bottom of this document.
                </p>
                <p style={{ marginBottom: '20px' }}>
                    Under certain US state data protection laws, you can designate an authorised
                    agent to make a request on your behalf. We may deny a request from an
                    authorised agent that does not submit proof that they have been validly
                    authorised to act on your behalf in accordance with applicable laws.
                </p>

                <h2 style={{ marginBottom: '10px' }}>Request Verification</h2>
                <p style={{ marginBottom: '20px' }}>
                    Upon receiving your request, we will need to verify your identity to determine
                    you are the same person about whom we have the information in our system.
                    We will only use personal information provided in your request to verify your
                    identity or authority to make the request. However, if we cannot verify your
                    identity from the information already maintained by us, we may request that
                    you provide additional information for the purposes of verifying your identity
                    and for security or fraud-prevention purposes.
                </p>

                <p style={{ marginBottom: '20px' }}>
                    If you submit the request through an authorised agent, we may need to collect
                    additional information to verify your identity before processing your request
                    and the agent will need to provide a written and signed permission from you
                    to submit such a request on your behalf.
                </p>

                <h2 style={{ marginBottom: '10px' }}>Appeals</h2>
                <p style={{ marginBottom: '20px' }}>
                    Under certain US state data protection laws, if we decline to take action
                    regarding your request, you may appeal our decision by emailing us at
                    <strong> admin@gloton.co.uk</strong>. We will inform you in writing of any
                    action taken or not taken in response to the appeal, including a written
                    explanation of the reasons for the decisions. If your appeal is denied, you
                    may submit a complaint to your state attorney general.
                </p>

                <h2 style={{ marginBottom: '10px' }}>California 'Shine The Light' Law</h2>
                <p style={{ marginBottom: '20px' }}>
                    California Civil Code Section 1798.83, also known as the 'Shine The Light' law,
                    permits our users who are California residents to request and obtain from us,
                    once a year and free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for direct marketing purposes
                    and the names and addresses of all third parties with which we shared personal
                    information in the immediately preceding calendar year. If you are a California
                    resident and would like to make such a request, please submit your request
                    in writing to us by using the contact details provided in the section
                    <strong> 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?'</strong>
                </p>

                <h2 style={{ marginBottom: '10px' }}>DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                <p style={{ marginBottom: '20px' }}>
                    In Short: Yes, we will update this notice as necessary to stay compliant
                    with relevant laws. We may update this Privacy Notice from time to time.
                    The updated version will be indicated by an updated 'Revised' date at the
                    top of this Privacy Notice. If we make material changes to this Privacy Notice,
                    we may notify you either by prominently posting a notice of such changes
                    or by directly sending you a notification. We encourage you to review this
                    Privacy Notice frequently to be informed of how we are protecting your information.
                </p>

                <h2 style={{ marginBottom: '10px' }}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                <p style={{ marginBottom: '20px' }}>
                    If you have questions or comments about this notice, you may email us at
                    <strong> admin@gloton.co.uk</strong>
                </p>
                <h2 style={{ marginBottom: '10px' }}>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                <p>
                    Based on the applicable laws of your country or state of residence in the US,
                    you may have the right to request access to the personal information we collect
                    from you, details about how we have processed it, correct inaccuracies, or delete
                    your personal information. You may also have the right to withdraw your consent
                    to our processing of your personal information. These rights may be limited in
                    some circumstances by applicable law. To request to review, update, or delete
                    your personal information, please contact us at the details provided above.
                </p>
            </div>
            <Footer/>
        </>
    )
}

export default Privacy;