import { useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './contact.css';

// Extend the Window interface to include hbspt
declare global {
  interface Window {
    hbspt: any;
  }
}
const Contact = () => {
  useEffect(() => {
    // Prevent adding the form again if it's already created
    if (document.getElementById('hs-form-embed')) {
      return;
    }

    const script = document.createElement('script');
    script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "145178949",
          formId: "20873089-515b-43c0-845a-fd4c34737c22",
          target: "#hubspot-form"
        });
      }
    };
    document.body.appendChild(script);

    return () => {
      // Cleanup if necessary (e.g., removing the form)
      const form = document.getElementById('hubspot-form');
      if (form) {
        form.innerHTML = '';
      }
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className="contact-container">
        <h1>Contact Us</h1>
        <div className="contact-form" id="hubspot-form"></div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
