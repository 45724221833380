import './../../styles/global.css'; // Global styling import
import './mission.css'; // Ensure this file exists and contains styles
import { useEffect } from 'react';

const logos = [
    "/8x8.svg",
    "/qatarenergy.png",
    "/creditsafe-logo.svg",
    "/TechSimulater-Logo.png",
  ];

  const Mission = () => {
      useEffect(() => { 
          // Adding IntersectionObserver for animations (fade-up, fade-in, slide-in-left)
          const elements = document.querySelectorAll('.fade-up, .fade-in, .slide-in-left');
          const observer = new IntersectionObserver((entries) => {
              entries.forEach(entry => {
                  if (entry.isIntersecting) {
                      entry.target.classList.add('in-view');
                  }             
              });
          });
  
          elements.forEach(element => {
              observer.observe(element);
          });
      }, []);
  
      return (
          <div className="section section-mission full-width-container">
              <div className="grad-top"></div>
  
              {/* Rounded shapes container */}
              <div className="rounded-lines-container slide-in-left"></div> 
  
              <div className="text-box-mid">
                  <h2 className="nomargin text-gold text-center fade-up">
                      TRANSFORMING GLOBAL COMMERCE
                  </h2>
                  <h2 className="text-white fade-up">
                      TRADE BY TRADE
                  </h2>
                  <div className="flexbox hero fade-up">
                      <p className="p-large text-center">
                      Join us in driving global commerce forward with cutting-edge technologies, expert insights, and a community of dedicated trade professionals. 
                      </p>
                      <a href="mailto:info@gloton.co.uk" className="btn w-button">Connect</a>
                  </div>
                  
                  {/* Carousel */}
                    <div className="partner-logos fade-in">
                        <div className="partner-logos-inner">
                            {logos.concat(logos).map((logo, index) => (
                                <div className="partner-logo" key={index}>
                                    <img src={logo} alt={`Partner Logo ${index}`} />
                                </div>
                            ))}
                        </div>
                    </div>
              </div>
          </div>
      );
  };
  
  export default Mission;
  