import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CryptoJS from 'crypto-js'; // Import crypto-js
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './verify.css';

gsap.registerPlugin(ScrollTrigger);

const Verify = () => {
    const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
    const [error, setError] = useState<string>('');
    const [verificationStatus, setVerificationStatus] = useState<'success' | 'error' | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const verifySectionRef = useRef(null);
    const dropAreaRef = useRef<HTMLDivElement | null>(null); // Reference to drop area

    useEffect(() => {
        gsap.fromTo(
            verifySectionRef.current,
            { opacity: 0, y: 50 },
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: 'power2.out',
                stagger: 0.1,
            }
        );
    }, []);

    // Scroll the page to center the drop area when file is dragged
    const scrollToCenter = () => {
        if (dropAreaRef.current) {
            const dropArea = dropAreaRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const scrollY = window.scrollY + dropArea.top - (windowHeight / 2) + (dropArea.height / 2);
            window.scrollTo({ top: scrollY, behavior: 'smooth' });
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        const validFiles = files.filter(file => file.type === 'application/pdf');

        if (validFiles.length !== files.length) {
            setError('Only PDF files are allowed.');
        } else {
            setError('');
            setDroppedFiles(validFiles);
            validFiles.forEach(file => processFile(file));
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        scrollToCenter(); // Call scroll function when dragging over
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        scrollToCenter(); // Call scroll function when drag enters the drop area
    };

    const handleClearFiles = () => {
        setDroppedFiles([]);
        setVerificationStatus(null);
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        const validFiles = files.filter(file => file.type === 'application/pdf');

        if (validFiles.length !== files.length) {
            setError('Only PDF files are allowed.');
        } else {
            setError('');
            setDroppedFiles(validFiles);
            validFiles.forEach(file => processFile(file));
        }
    };

    const processFile = async (file: File) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const contents = e.target?.result as ArrayBuffer;
            const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(contents));
            const md5Hash = CryptoJS.MD5(wordArray).toString();
            await verifyFile(md5Hash, file.name);
        };
        reader.readAsArrayBuffer(file);
    };

    const verifyFile = async (md5Hash: string, fileName: string) => {
        try {
            const response = await fetch('https://ace6ta1mq4.execute-api.eu-north-1.amazonaws.com/prod/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ MD5Hash: md5Hash }),
            });

            const data = await response.json();
            if (response.ok) {
                setVerificationStatus('success');
            } else if (response.status === 404) {
                setVerificationStatus('error');
            } else {
                setVerificationStatus('error');
            }
        } catch (error) {
            setVerificationStatus('error');
        }
    };

    return (
        <>
            <Navbar />
            <div className="verify-container">
                <div className="page-header-verify" ref={verifySectionRef}>
                    <h1>Document Verification</h1>
                    <p className="sub-heading-verify">
                        Every PDF document distributed by Gloton is approved for authenticity. If you are unsure whether a PDF document you received, claiming to be from Gloton, is genuine, or if you have reason to suspect it may have been tampered with, please use the document checker below to verify its authenticity with our internal database.
                    </p>
                </div>

                <div
                    ref={dropAreaRef} // Ref for drop area
                    className={`file-drop-area ${verificationStatus === 'success' ? 'success' : verificationStatus === 'error' ? 'error' : ''}`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter} // Handle drag enter event to trigger scroll
                >
                    <p>Drag and drop your documents here (PDF)</p>

                    {verificationStatus === null && (
                        <div className="file-input-container">
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={handleFileInputChange}
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                multiple
                            />
                            <button onClick={() => fileInputRef.current?.click()}>Upload Document</button>
                            {error && <p className="error-message">{error}</p>}
                        </div>
                    )}

                    {verificationStatus === 'success' && (
                        <div className="verification-success">
                            <div className="success-check">&#10004;</div>
                            <p>This is a verified document.</p>
                        </div>
                    )}

                    {verificationStatus === 'error' && (
                        <div className="verification-error">
                            <div className="error-cross">&#10060;</div>
                            <p>The document cannot be verified or has been tampered with.</p>
                        </div>
                    )}
                </div>

                {verificationStatus && (
                    <div className="reset-container">
                        <button onClick={handleClearFiles} className="reset-button">Check Another File</button>
                    </div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Verify;
