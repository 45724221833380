import "../../styles/global.css";
import "./footer.css";

const Footer = () => {
	const currentYear = new Date().getFullYear();

	return (
		<div className="footer">
			<div className="footer-globe" style={{ backgroundImage: 'url("/static-background-globe.png")' }}>
			</div>
			<div className="container-footer border-top">
				<div className="footer-content">
					<div className="footer-location">
						<a href="/" className="brand brand-footer">
							<img src="/logo-together.png" alt="Logo" className="logo-footer" />
						</a>
						<p className="footer-p">
							<a href="mailto:info@gloton.co.uk" className="footer-link">info@gloton.co.uk</a><br />
							<a href="tel:+442033869440" className="footer-link">+44 (0) 20 3386 9440</a><br />
							Suite 2803, Floor 28, Gherkin, 30 St Mary Axe,<br /> London, United Kingdom, EC3A 8BF
						</p>
					</div>
					<div className="contact-button">
						<a href="/contact" className="btn-contact">Get in Touch</a>
					</div>
				</div>
				<div className="footer-info">
					<div className="footer-copyright">
						<p>© {currentYear} GLOTON. All Rights Reserved</p>
					</div>
					<div className="footer-links-horizontal">
						<a href="/privacy">Privacy Policy</a>
						<a href="/terms">Terms and Conditions</a>
					</div>
					<div className="footer-links">
						<a href="https://www.linkedin.com/company/gloton/about/" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block-footer">
							<img src="/Social/linkedin.svg" loading="lazy" alt="LinkedIn" />
						</a>
						<a href="https://twitter.com/glotonTrade" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block-footer">
							<img src="/Social/twitter.svg" loading="lazy" alt="Twitter" />
						</a>
						<a href="https://www.instagram.com/glotonTrade" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block-footer">
							<img src="/Social/instagram.svg" loading="lazy" alt="Instagram" />
						</a>
						<a href="https://www.facebook.com/glotonTrade" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block-footer">
							<img src="/Social/facebook.svg" loading="lazy" alt="Facebook" />
						</a>
						<a href="https://www.tiktok.com/@glotonTrade" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block-footer">
							<img src="/Social/tiktok.svg" loading="lazy" alt="TikTok" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
