"use client"; // Ensure this file is a client component

import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './Navbar.css';

gsap.registerPlugin(ScrollTrigger);

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null); // Ref for the navbar
  const location = useLocation(); // Get current route

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let lastScrollY = window.scrollY;

    // Detect scroll direction and apply fade effect
    const updateNavbarVisibility = () => {
      if (window.scrollY === 0) {
        // At the top of the page: always show the navbar
        gsap.to(navbarRef.current, { opacity: 1, duration: 0.2 });
      } else if (window.scrollY > lastScrollY) {
        // Scrolling down: fade out the navbar
        gsap.to(navbarRef.current, { opacity: 0, duration: 0.2 });
      } else {
        // Scrolling up: fade in the navbar
        gsap.to(navbarRef.current, { opacity: 1, duration: 0.2 });
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', updateNavbarVisibility);

    return () => {
      window.removeEventListener('scroll', updateNavbarVisibility);
    };
  }, []);

  return (
    <div ref={navbarRef} className="navbar">
      {/* Logo section */}
      <div className="logo-container">
        <a href="/">
          <img src="/logo.png" alt="Logo" className="logo" />
        </a>
      </div>

      {/* Hamburger icon */}
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {/* Navigation links */}
      <div className={`nav-links ${isOpen ? 'active' : ''}`}>
        <Link
          to="/"
          className={location.pathname === '/' ? 'active' : ''} // Highlight current route
        >
          Home
        </Link>
        <Link
          to="/aboutus"
          className={location.pathname === '/aboutus' ? 'active' : ''}
        >
          About Us
        </Link>
        <Link
          to="/services"
          className={location.pathname === '/services' ? 'active' : ''}
        >
          Services
        </Link>
        <Link
          to="/contact"
          className={location.pathname === '/contact' ? 'active' : ''}
        >
          Contact
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
