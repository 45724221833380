"use client"; // Ensure this file is a client component

import { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../../styles/global.css';
import './scroll.css'; // Ensure this file exists and contains styles

gsap.registerPlugin(ScrollTrigger);

const ScrollFeature = () => {

  useEffect(() => {

    // Setting up two animations:
    const mm = gsap.matchMedia();
    ScrollTrigger.refresh(); // Recalculates the start and end positions for all triggers

    // Desktop-specific animation (min-width: 1001px)
    mm.add("(min-width: 1001px)", () => {
      // GSAP timeline for card animations on desktop
      const tlDesktopCards = gsap.timeline({
        scrollTrigger: {
          trigger: ".prod-box",
          start: "top top",
          end: () => '+=2000',
          scrub: 1,
          pin: true, // Pinning enabled for desktop
          markers: false, // Remove in production
        }
      });

      const cards = gsap.utils.toArray(".card");

      // Set initial positions for cards (desktop)
      gsap.set(cards, { autoAlpha: 0 });
      gsap.set(cards[0], { autoAlpha: 1 });

      // Fade in and out cards as you scroll (desktop)
      cards.forEach((card, i) => {
        const next = cards[i + 1];
        tlDesktopCards.to(card, {
          autoAlpha: 1,
          duration: 0.5,
          ease: "power1.in",
        }).to(card, {
          autoAlpha: 0,
          duration: 0.5,
          ease: "power1.in",
        }, "+=3"); // Maintain static pause for 3 seconds before transitioning
      });

      // GSAP timeline for spinner animations (desktop)
      const tlDesktopSpinner = gsap.timeline({
        scrollTrigger: {
          trigger: ".prod-box",
          start: "top top",
          end: () => '+=2000',
          scrub: 1,
          markers: false, // Removed in production
        }
      }); 

      // Initial setup (state 1)
      gsap.set(".prod-spinner", {
        translate3d: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: -33.5,
        skew: 0,
      });
      gsap.set(".prod-icon-img.img-station", {
        rotateZ: 33.5,
        scale: 1,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-img.img-paas", {
        rotateZ: 33.5,
        scale: 0.6,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-img.img-app", {
        rotateZ: 33.5,
        scale: 0.6,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-box.prod-station", {
        scale: 1,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-box.prod-paas", {
        scale: 0.6,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-box.prod-app", {
        scale: 0.6,
        ease: "power1.in",
      });

      // State 2: Simultaneous animation of spinner and icons
      tlDesktopSpinner.to(".prod-spinner", {
        rotateZ: 0,
        duration: 3,
        ease: "power1.in",
      })
        .to(".prod-icon-img.img-station", {
          rotateZ: 0,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-img.img-paas", {
          rotateZ: 0,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-img.img-app", {
          rotateZ: 0,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-box.prod-station", {
          scale: 0.6,
          ease: "power1.in",
        }, "<") // Icons start scaling down at the same time
        .to(".prod-icon-box.prod-paas", {
          scale: 1,
          ease: "power1.in",
        }, "<")
        .to(".prod-icon-box.prod-app", {
          scale: 0.6,
          ease: "power1.in",
        }, "<");

      // State 3: Simultaneous transition to the final state
      tlDesktopSpinner.to(".prod-spinner", {
        rotateZ: 33.5,
        duration: 3,
        ease: "power1.in",
      })
        .to(".prod-icon-img.img-station", {
          rotateZ: -33.5,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-img.img-paas", {
          rotateZ: -33.5,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-img.img-app", {
          rotateZ: -33.5,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-box.prod-station", {
          scale: 0.6,
          ease: "power1.in",
        }, "<")
        .to(".prod-icon-box.prod-paas", {
          scale: 0.6,
          ease: "power1.in",
        }, "<")
        .to(".prod-icon-box.prod-app", {
          scale: 1,
          ease: "power1.in",
        }, "<");
    });

    // Mobile-specific animation (max-width: 1000px)
    mm.add("(max-width: 1000px)", () => {
      // GSAP timeline for card animations on mobile
      const tlMobileCards = gsap.timeline({
        scrollTrigger: {
          trigger: ".prod-box", // Use the .prod-box element as the trigger
          start: "-=100 top center", // Adjust start position as needed
          end: "+=110%",
          scrub: 1,
          markers: false, // Remove in production
        }
      });

      const cards = gsap.utils.toArray(".card");

      // Set initial positions for cards (mobile)
      gsap.set(cards, { autoAlpha: 0 });
      gsap.set(cards[0], { autoAlpha: 1 });

      // Fade in and out cards as you scroll (mobile)
      cards.forEach((card, i) => {
        const nextCard = cards[i + 1] || cards[0]; // Loop back to the first card
        tlMobileCards.to(card, {
          autoAlpha: 1,
          duration: 0.5,
          y: 90,
          ease: "power1.inOut",
        }, ">")
          .to(card, {
            autoAlpha: 0,
            duration: 0.2,
            y: 90,
            ease: "power1.inOut",
          }, ">") // Maintain static pause for 2 seconds before transitioning
          .to(nextCard, {
            autoAlpha: 1,
            duration: 0.2,
            y: 90,
            ease: "power1.inOut",
          }, ">");
      });

      // Mobile-specific animation for spinner
      const tlMobileSpinner = gsap.timeline({
        scrollTrigger: {
          trigger: ".prod-box", // Use the .prod-box element as the trigger
          start: "-=20 top center", // Adjust start position as needed
          end: "+=110%",
          scrub: 1,
          markers: false, // Remove in production
        }
      });

      // Initial setup (state 1)
      gsap.set(".prod-spinner", {
        translate3d: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: -33.5,
        skew: 0,
      });
      gsap.set(".prod-icon-img.img-station", {
        rotateZ: 33.5,
        scale: 1,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-img.img-paas", {
        rotateZ: 33.5,
        scale: 0.6,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-img.img-app", {
        rotateZ: 33.5,
        scale: 0.6,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-box.prod-station", {
        scale: 1,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-box.prod-paas", {
        scale: 0.6,
        ease: "power1.in",
      });
      gsap.set(".prod-icon-box.prod-app", {
        scale: 0.6,
        ease: "power1.in",
      });

      // State 2: Simultaneous animation of spinner and icons
      tlMobileSpinner.to(".prod-spinner", {
        rotateZ: 0,
        duration: 3,
        ease: "power1.in",
      })
        .to(".prod-icon-img.img-station", {
          rotateZ: 0,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-img.img-paas", {
          rotateZ: 0,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-img.img-app", {
          rotateZ: 0,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-box.prod-station", {
          scale: 0.6,
          ease: "power1.in",
        }, "<") // Icons start scaling down at the same time
        .to(".prod-icon-box.prod-paas", {
          scale: 1,
          ease: "power1.in",
        }, "<")
        .to(".prod-icon-box.prod-app", {
          scale: 0.6,
          ease: "power1.in",
        }, "<");

      // State 3: Simultaneous transition to the final state
      tlMobileSpinner.to(".prod-spinner", {
        rotateZ: 33.5,
        duration: 3,
        ease: "power1.in",
      })
        .to(".prod-icon-img.img-station", {
          rotateZ: -33.5,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-img.img-paas", {
          rotateZ: -33.5,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-img.img-app", {
          rotateZ: -33.5,
          duration: 3,
          ease: "power1.in",
        }, "<") // Start simultaneously
        .to(".prod-icon-box.prod-station", {
          scale: 0.6,
          ease: "power1.in",
        }, "<")
        .to(".prod-icon-box.prod-paas", {
          scale: 0.6,
          ease: "power1.in",
        }, "<")
        .to(".prod-icon-box.prod-app", {
          scale: 1,
          ease: "power1.in",
        }, "<");

    });

    // Cleanup GSAP on component unmount
    return () => {
      mm.revert(); // Clean up all matchMedia listeners
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <div className="section section-products">
      {/* <div className="prod-triggers"></div> */}
      <div className="prod-box">
        <div className="container container-products">
          <div className="card">
            <h2 className="card-title">For Suppliers</h2>
            <p className="card-description">Access a network of buyers from around the world.</p>
            <a href="/services" className="card-button w-button">Find Out More</a>
          </div>
          <div className="card">      
            <h2 className="card-title">For Buyers</h2>
            <p className="card-description">Access a network of suppliers from around the world.</p>
            <a href="/services" className="card-button w-button">Find Out More</a>
          </div>    
          <div className="card">
            <h2 className="card-title">Security</h2>
            <p className="card-description">We ensure your security through every step of the process.</p>
            <a href="/services" className="card-button w-button">Find Out More</a>
          </div>
          {/* Spinner Section */}
            <div className="prod-spinner">
              <div className="prod-icon-box prod-station">
                <img src="./handshake-svgrepo-com.svg" loading="lazy" className="prod-icon-img img-station" alt="Finding Buyers" />
              </div>
              <div className="prod-icon-box prod-paas">
                <img src="./manufacturer-svgrepo-com.svg" loading="lazy" className="prod-icon-img img-paas" alt="Finding Suppliers" />
              </div>
              <div className="prod-icon-box prod-app">
                <img src="./shield-check-svgrepo-com.svg" loading="lazy" className="prod-icon-img img-app" alt="secure" />
              </div>
            </div>  
          </div>
        </div>
    </div>  
  );
};

export default ScrollFeature;
