import React, { useRef, useState, useEffect } from 'react';
import CryptoJS from 'crypto-js'; // Import crypto-js for hash generation
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './upload.css';

const Upload = () => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [error, setError] = useState<string>('');
    const [uploadStatus, setUploadStatus] = useState<string | null>(null); // Store status of file upload
    const [password, setPassword] = useState<string>(''); // State for storing user password
    const [userName, setUserName] = useState<string>(''); // State for storing user name
    const [passwordError, setPasswordError] = useState<string>(''); // State for password error
    const [userNameError, setUserNameError] = useState<string>(''); // State for user name error
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    // Check if the password is saved in localStorage when the component loads
    useEffect(() => {
        const savedPassword = localStorage.getItem('userPassword');
        if (savedPassword) {
            setPassword(savedPassword); // Automatically set the password if saved in localStorage
        }
    }, []); // This effect runs only when the component is mounted

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        const validFiles = files.filter(file => file.type === 'application/pdf');

        if (validFiles.length !== files.length) {
            setError('Only PDF files are allowed.');
        } else {
            setError('');
            setUploadedFiles(validFiles);
            validFiles.forEach(file => processFile(file));
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        const validFiles = files.filter(file => file.type === 'application/pdf');

        if (validFiles.length !== files.length) {
            setError('Only PDF files are allowed.');
        } else {
            setError('');
            setUploadedFiles(validFiles);
            validFiles.forEach(file => processFile(file));
        }
    };

    const processFile = async (file: File) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const contents = e.target?.result as ArrayBuffer;
            // Generate MD5 hash
            const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(contents));
            const md5Hash = CryptoJS.MD5(wordArray).toString();

            console.log(`File Name: ${file.name}`);
            console.log(`MD5 Hash: ${md5Hash}`); // Log the MD5 hash

            // Call the API to upload the file's hash to DynamoDB
            await uploadFileToDB(md5Hash, file.name);
        };
        reader.readAsArrayBuffer(file);
    };

    const uploadFileToDB = async (md5Hash: string, fileName: string) => {
        if (!password) {
            setPasswordError('Password is required.');
            return;
        }

        if (!userName) {
            setUserNameError('Name is required.');
            return;
        }

        // Save the password to localStorage if it's not already saved
        localStorage.setItem('userPassword', password);

        try {
            console.log(`Uploading file: ${fileName} with MD5 hash: ${md5Hash}`);

            // Construct the payload, wrap inside the "body" key
            const payload = {
                body: JSON.stringify({
                    MD5Hash: md5Hash,
                    DocumentName: fileName,
                    CreatedBy: userName, // Use the user name entered by the user
                    Password: password // Use the password entered by the user
                })
            };

            // Make the API request to upload file data to DynamoDB
            const response = await fetch('https://l87w8vhn0l.execute-api.eu-north-1.amazonaws.com/prod/upload', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload), // Stringify the entire payload
            });

            const responseData = await response.json();

            // Check if statusCode 200 is in the response and message is Document successfully uploaded
            if (response.status === 200) {
                const parsedBody = JSON.parse(responseData.body); // Parse the stringified response body

                if (parsedBody.message === "Document successfully uploaded") {
                    console.log('File successfully uploaded to DynamoDB:', parsedBody);
                    setUploadStatus('success');
                } else {
                    console.error('Unexpected response message:', parsedBody.message);
                    setUploadStatus('error');
                }
            } else if (response.status === 403) {
                console.error('Unauthorized: Incorrect password');
                setPasswordError('Incorrect password. Please try again.');
                setUploadStatus('error');
            } else {
                console.error('Error uploading the file:', responseData);
                setUploadStatus('error');
            }
        } catch (error) {
            setUploadStatus('error');
            console.error('Error uploading file:', error);
        }
    };

    return (
        <>
            <Navbar />
            <div className="upload-container">
                <div className="page-header">
                    <h1>Upload Your Files</h1>
                </div>

                <div
                    className="file-drop-area"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <p>Drag and drop your documents here (PDF only)</p>
                    <div className="file-input-container">
                        <input
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileInputChange}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            multiple
                        />
                        <button onClick={() => fileInputRef.current?.click()}>Upload Document</button>
                        {error && <p className="error-message">{error}</p>}
                    </div>

                    {/* Input for user to provide name */}
                    <div className="name-input-container">
                        <input
                            type="text"
                            placeholder="Enter your name"
                            value={userName}
                            onChange={(e) => {
                                setUserName(e.target.value);
                                setUserNameError(''); // Reset name error on input change
                            }}
                        />
                        {userNameError && <p className="error-message">{userNameError}</p>}
                    </div>

                    {/* Input for user to provide password */}
                    <div className="password-input-container">
                        <input
                            type="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordError(''); // Reset password error on input change
                            }}
                        />
                        {passwordError && <p className="error-message">{passwordError}</p>}
                    </div>

                    {/* Show upload status */}
                    {uploadStatus === 'success' && (
                        <div className="upload-success">
                            <p>File successfully uploaded to the database!</p>
                        </div>
                    )}
                    {uploadStatus === 'error' && (
                        <div className="upload-error">
                            <p>Error uploading the file. Please try again later.</p>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Upload;
