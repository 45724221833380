import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';

import "./terms.css"

const Terms = () => (
    <>
        <Navbar />
        <div className='terms-block'>
            <h1>Terms and Conditions</h1>
            <p>Last updated: 09/10/2024</p>

            <h2>AGREEMENT TO OUR LEGAL TERMS</h2>
            <p>
                We are GLOTON TRADE MANAGEMENT NETWORK LTD ("Company," "we," "us," "our").
                We operate, as well as any other related products and services that refer or link to these legal terms
                (the "Legal Terms") (collectively, the "Services"). You can contact us by email at: <a href="mailto:admin@gloton.co.uk">admin@gloton.co.uk</a>
            </p>
            <p>
                These Legal Terms constitute a legally binding agreement made between you,
                whether personally or on behalf of an entity ("you"), and GLOTON TRADE MANAGEMENT NETWORK LTD,
                concerning your access to and use of the Services. By accessing the Services, you have read, understood,
                and agree to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS,
                THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
            </p>

            <h2>SUPPLEMENTAL TERMS AND CHANGES</h2>
            <p>
                Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby
                expressly incorporated by reference. We reserve the right, in our sole discretion, to make changes or modifications
                to these Legal Terms at any time and for any reason. We will alert you about any changes by updating the "Last updated"
                date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility
                to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been
                made aware of and accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such
                revised Legal Terms are posted.
            </p>

            <h2>OUR SERVICES</h2>
            <p>
                The information provided when using the Services is not intended for distribution to or use by any person or entity
                in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
                subject us to any registration requirement within such jurisdiction or country.
            </p>

            <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
            <p>
                We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases,
                functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the "Content"),
                as well as the trademarks, service marks, and logos contained therein (the "Marks").
            </p>

            <h2>USER REPRESENTATIONS</h2>
            <p>
                By using the Services, you represent and warrant that you have the legal capacity and agree to comply with these Legal Terms;
                you are not a minor in the jurisdiction in which you reside; and your use of the Services will not violate any applicable law or regulation.
            </p>

            <h2>PROHIBITED ACTIVITIES</h2>
            <p>
                You agree not to access or use the Services for any purpose other than that for which we make the Services available.
                You may not engage in any unauthorized use of the Services, including attempting to impersonate another user or
                person or collecting usernames or email addresses of users for the purpose of sending unsolicited emails.
            </p>

            <h2>MODIFICATIONS AND INTERRUPTIONS</h2>
            <p>
                We reserve the right to modify or discontinue all or part of the Services without notice at any time. We cannot guarantee
                the Services will be available at all times and are not liable for any downtime or interruptions.
            </p>

            <h2>LIMITATIONS OF LIABILITY</h2>
            <p>
                To the maximum extent permitted by law, we shall not be liable for any damages, including lost profit or data,
                arising from your use of the Services.
            </p>

            <h2>INDEMNIFICATION</h2>
            <p>
                You agree to indemnify and hold us harmless from any claims or demands, including reasonable attorneys' fees,
                arising out of your use of the Services or violation of these Legal Terms.
            </p>

            <h2>CONTACT US</h2>
            <p>If you have any questions about these Legal Terms, you may contact us at:</p>
            <p>GLOTON TRADE MANAGEMENT NETWORK LTD</p>
            <p>Email: <a href="mailto:admin@gloton.co.uk">admin@gloton.co.uk</a></p>
        </div>
        <Footer />
    </>
);

export default Terms;
