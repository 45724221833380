import threeGlobe from 'three-globe';
 
class Globe extends threeGlobe {
  constructor({
    waitForGlobeReady,
    animateIn,
  }: {
    waitForGlobeReady?: boolean;
    animateIn?: boolean;
  }) {
    super({ waitForGlobeReady, animateIn });
 
    // Label data for the cities
    const gData = [
      {
        lat: 51.5074,
        lng: -0.1278,
        size: 1, // Larger dot for London
        color: 'gold',
        label: 'London'
      },
      {
        lat: 40.7128,
        lng: -74.0060,
        size: 0.7,
        color: 'white',
        label: 'New York'
      },
      {
        lat: 55.7558,
        lng: 37.6173,
        size: 0.7,
        color: 'white',
        label: 'Moscow'
      },
      {
        lat: 50.4501,
        lng: 30.5234,
        size: 0.7,
        color: 'white',
        label: 'Kyiv'
      },
      {
        lat: -23.5505,
        lng: -46.6333,
        size: 0.7,
        color: 'white',
        label: 'Sao Paulo'
      },
      {
        lat: 31.2304,
        lng: 121.4737,
        size: 0.7,
        color: 'white',
        label: 'Shanghai'
      },
      {
        lat: 22.3193,
        lng: 114.1694,
        size: 0.7,
        color: 'white',
        label: 'Hong Kong'
      },
      {
        lat: 25.276987,
        lng: 55.296249,
        size: 0.5,
        color: 'white',
        label: ' Dubai'
      },
      {
        lat: 25.276987,
        lng: 51.521214,
        size: 0.5,
        color: 'white',
        label: 'Doha'
      },
      {
        lat: 6.5244,
        lng: 3.3792,
        size: 0.7,
        color: 'white',
        label: 'Lagos'
      },
      {
        lat: -26.2041,
        lng: 28.0473,
        size: 0.7,
        color: 'white',
        label: 'Johannesburg'
      },
      {
        lat: 48.1351,
        lng: 11.5820,
        size: 0.7,
        color: 'white',
        label: 'Munich'
      },
      {
        lat: 41.0082,
        lng: 28.9784,
        size: 0.7,
        color: 'white',
        label: 'Istanbul'
      },
      {
        lat: 19.0760,
        lng: 72.8777,
        size: 0.7,
        color: 'white',
        label: 'Mumbai'
      },
      {
        lat: 31.5497,
        lng: 74.3436,
        size: 0.7,
        color: 'white',
        label: 'Lahore'
      }
    ];
 
    // Set up the globe with labels
    this.labelsData(gData)
      .labelText((d: any) => d.label) // Text for each label
      .labelSize((d: any) => d.size * 2) // Adjust size as needed
      .labelDotRadius((d: any) => d.size / 5) // Adjust dot radius as needed
      .labelColor((d: any) => d.color); // Color of each label
  }
  
  tick: (delta: number) => void = () => {};
}
 
export { Globe };