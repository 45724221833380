import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import global styles
import './styles/global.css';  // Ensure the correct path to your global CSS file

// Import components and pages
import Home from './pages/Home/Home';  // Home page component
import AboutUs from './pages/AboutUs/AboutUs';  // About Us page component
import Contact from './pages/Contact/Contact';  // Contact page component
import Services from './pages/Services/Services';  // Services page component
import Privacy from './pages/Privacy/Privacy';  // Privacy page component
import Verify from './pages/Verify/Verify'; // Verify page
import Upload from './pages/Upload/Upload'; // Upload page
import Terms from './pages/Terms/Terms';

// Password protection logic contained within the route file
type PasswordProtectProps = {
  children: React.ReactNode; // Explicitly typing children as ReactNode
};

const PasswordProtect: React.FC<PasswordProtectProps> = ({ children }) => {
  const [password, setPassword] = useState(''); // State to store the current password input
  const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track if the user is authenticated
  const [error, setError] = useState(''); // State to store any error messages

  const correctPassword = 'eNamaGERMELD'; // Define your correct password here

  // Effect to check localStorage for a saved password
  useEffect(() => {
    const savedPassword = localStorage.getItem('savedPassword');
    if (savedPassword === correctPassword) {
      setIsAuthenticated(true); // Auto-authenticate if the saved password matches
    }
  }, []); // This effect runs once when the component is mounted

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === correctPassword) {
      localStorage.setItem('savedPassword', password); // Save password to localStorage
      setIsAuthenticated(true); // Set authentication to true
    } else {
      setError('Incorrect password, please try again.');
    }
  };

  if (isAuthenticated) {
    return <>{children}</>; // If authenticated, render the protected component
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <h2>Restricted Page</h2>
      <p>Please enter the password to access this page.</p>
      <form onSubmit={handlePasswordSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        />
        <button type="submit">Submit</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

function App() {
  return (
    <Router>
      {/* Define Routes for different pages */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} /> 
        <Route path="/privacy" element={<Privacy />} /> 
        <Route path="/terms" element={<Terms />} /> 
        <Route path="/verify" element={<Verify />} /> 
        
        {/* Wrap the /Upload route with PasswordProtect */}
        <Route
          path="/upload"
          element={
            <PasswordProtect>
              <Upload />
            </PasswordProtect>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
